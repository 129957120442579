<template>
  <v-container>
    <v-row>
      <v-col
        class="mb-5"
      >
        <p style="text-align: right;">事業者名（ #gtfs_id ）</p>
      </v-col>
      <v-col
        class="mb-5"
      >
        <v-text-field
          label="事業者名（ #gtfs_id ）"
          solo
          v-model="gtfs_id_input"
          list="gtfs_id_list"
        ></v-text-field>
        <datalist id="gtfs_id_list">  
          <option v-for="n in gtfs_id_list" :key="n">{{n}}</option>
        </datalist>
      </v-col>
      <v-col></v-col>
    </v-row>

    <v-row>
      <v-col
        class="mb-5"
      >
        <p style="text-align: right;">出発停留所名（ #stop_id ）</p>
      </v-col>
      <v-col
        class="mb-5"
      >
        <v-text-field
          label="stop_id"
          solo
          v-model="stop_id_input"
          autocomplete="on"
          list="stop_list"
        ></v-text-field>
        <datalist id="stop_list">  
          <option v-for="n in stop_list" :key="n">{{n}}</option>
        </datalist>
      </v-col>
      <v-col></v-col>
    </v-row>
    <!-- <v-row>
      <v-col
        class="mb-5"
      >
        <p style="text-align: right;">到着停留所名（ #stop_id ）</p>
      </v-col>
      <v-col
        class="mb-5"
      >
        <v-text-field
          label="to_stop_id"
          solo
          v-model="to_stop_id_input"
          autocomplete="on"
          list="to_stop_list"
        ></v-text-field>
        <datalist id="to_stop_list">  
          <option v-for="n in to_stop_list" :key="n">{{n}}</option>
        </datalist>
      </v-col>
      <v-col></v-col>
    </v-row> -->

    <v-row>
      <v-col
        class="mb-5"
      >
        <p style="text-align: right;">カードの色</p>
      </v-col>
      <v-col
        class="mb-5"
      >
        <v-text-field
          label="card color"
          solo
          v-model="card_color"
        ></v-text-field>
      </v-col>
      <v-col></v-col>
    </v-row>

    <v-row>
      <v-col
      class="mb-5"
      ></v-col>
      <v-col
      class="mb-5"
      >
      <center>
        <v-btn
          elevation="2"
          @click="btn"
          color="primary"
        >更新</v-btn>
      </center>
      </v-col>
      <v-col
      class="mb-5"
      ></v-col>
    </v-row>

    <v-row>
      <v-col
        cols="12"
        md="6"
      >
        <v-textarea
          solo
          disabled
          dark
          name="input-7-4"
          label="Solo textarea"
          v-model="tag_text"
        ></v-textarea>
      </v-col>
      <v-col
        class="mb-5"
        cols="12"
      >
        <h3>プレビュー</h3>
          <!--GTFSによる時刻表を表示-->
          <div id="textbox" how="from_now_with_last_left"></div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'HelloWorld',

    data: () => ({
      gtfs_id:"ToeiBus",
      stop_id:"0605-07",
      to_stop_id:"0605-07",
      gtfs_id_input:"都営バス（ #ToeiBus ）",
      stop_id_input:"品川駅港南口（ #0605-07 ）",
      to_stop_id_input:"品川駅港南口（ #0605-07 ）",
      card_color:"#eee",
      tag_text:'ここにタグが生成されます',
      limit:5,
      stop_list:[],
      to_stop_list:[],
      endPoint: 'https://butter.hatano-yuuta7921.workers.dev',
      // endPoint: 'http://localhost:8888',
      data_list:[],
      gtfs_id_list:[]
    }),
    methods:{
      btn:async function(){
        if(this.stop_id==''){
          alert('停留所名（ #stop_id ）になるように候補から選択してください')
        }
        //1240_02
        // id属性で要素を取得
        const textbox_element = document.getElementById('textbox');

        // 設定
        // const limit     = this.limit;
        // const how       = textbox_element.getAttribute("how");
        // const gtfs_id   = this.gtfs_id;
        const card_color= this.card_color;

        const today = new Date();
        const timezone = "Asia/Tokyo";
        const dateFormatter = new Intl.DateTimeFormat("ja-JP", {
            timeZone: timezone,
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
        });

        const formattedDate = dateFormatter.format(today);

        const [year, month, day] = formattedDate.split("/");

        const todayString = `${year}${month}${day}`;
        const query = {
          stop_ids:[this.stop_id],
          // limit:Number(limit),
          // how:how,
          // gtfs_id:gtfs_id
          date:todayString
        }

        const url = this.endPoint+'/?method=fetchTimeTableV1&gtfsID='+this.gtfs_id+'&options='+JSON.stringify(query);
        console.log(url)
        // let tmp_tag_text;
        await fetch(url)
        .then(function (data) {
          return data.json();
        })
        .then(function (json) {
          const n = textbox_element.childElementCount;
          for(let i=0;i<n;i++){
            textbox_element.firstElementChild.remove();
          }

          json.stop_times.forEach(st => {
            // 新しいHTML要素を作成
            const card = document.createElement('div');
            card.style['background-color'] = card_color;
            card.style['border-top-left-radius'] = "10px";
            card.style['border-top-right-radius'] = "10px";
            card.style['border-bottom-right-radius'] = "10px";
            card.style['border-bottom-left-radius'] = "10px";
            card.style['text-align'] = "left";
            card.style['display'] = "absolute";
            
            const box = document.createElement('div')
            const h = document.createElement('h3')
            h.textContent = st.stop_headsign;
            box.appendChild(h)
            var new_element = document.createElement('p');
            // if(textbox_element.getAttribute("how")=='hhmm')
            new_element.textContent = st.departure_time.slice(0,5)//+' (+X min)';
            box.appendChild(new_element)
            box.style['text-align']="left";
            box.style['margin']="10px";
            box.style['padding']="10px"
            // box.style['line-height']="5px"
            box.style['display'] = "absolute";

            card.appendChild(box)
            // 指定した要素の中の末尾に挿入
            textbox_element.appendChild(card);
          });
          const license_info = document.createElement('p');
          let a = document.createElement('a');
          a.innerHTML = json.DataProperties.license;
          a.href = json.DataProperties.licenseUrl;
          // license_info.innerHTML='CCBY 4.0'
          let aa = document.createElement('a');
          aa.innerHTML = json.DataProperties.providerAgencyName;
          aa.href = json.DataProperties.providerUrl;
          license_info.appendChild(aa);
          license_info.appendChild(a);
          textbox_element.appendChild(license_info);
        });
        
        this.tag_text = '<!--GTFSによる時刻表を表示-->'+"\n"+'<div id="textbox" gtfs_id="'+this.gtfs_id+'" stop_id="'+this.stop_id+'" limit="5" how="from_now_with_last_left" card_color="'+this.card_color+'"></div>' + "\n<script src=\""+this.endPoint+"/cros-storage/gtfs-javascript.js"+'"'+'><'+'/script>';
      },
      stopInput2StopID:async function(input){
        let arr = input.split('（ #')
        if(arr.length==1){
          let ans;
          this.stop_id = '';
          const url = this.endPoint+'/?method=fetchStopsV1&gtfs_id='+this.gtfs_id+'&name='+input;
          console.log(url)
          // let tmp_tag_text;
          await fetch(url)
            .then(function (data) {
              return data.json();
            })
            .then(function (json) {
              ans = json;
            })
          
          if(ans.stops!=null){
            const list = [];
            for(let i=0;i<ans.stops.length;i++){
              const stop = ans.stops[i];

              if (list.length < 20){
                list.push(stop.stop_name+'（ #'+stop.stop_id+' ）');
              }
            }
            return {
              stop_id: '',
              list:list
            }
          }
        } else {
          arr = arr[1].split(' ）')
          return {
            stop_id: arr[0],
            list:[]
          }
        }
      }
    },
    watch:{
      stop_id_input:async function(){
        const res = await this.stopInput2StopID(this.stop_id_input)
        if(res.stop_id!=''){
          this.stop_id = res.stop_id;
        }
        if(res.list.length>0){
          this.stop_list = res.list;
        }
      },
      // to_stop_id_input:async function(){
      //   const res = await this.stopInput2StopID(this.to_stop_id_input)
      //   if(res.stop_id!=''){
      //     this.to_stop_id = res.stop_id;
      //   }
      //   if(res.list.length>0){
      //     this.to_stop_list = res.list;
      //   }
      // },
      gtfs_id_input:async function(){

        let arr = this.gtfs_id_input.split('（ #')
        if(arr.length==1){
          this.gtfs_id_list = [];
          this.data_list.forEach(d => {
            if(d.name.match(this.gtfs_id_input)){
              this.gtfs_id_list.push(d.name+'（ #'+d.gtfs_id+' ）');
            }
          });
        } else {
          arr = arr[1].split(' ）')
          this.gtfs_id = arr[0];
        }
      }
    },
    mounted:async function(){
      let ans;
      await fetch(this.endPoint+'/?method=dataListV1')
        .then(function (data) {
          return data.json();
        })
        .then(function (json) {
          ans = json;
        })
      this.data_list = ans.data_list;
    }
  }
</script>
